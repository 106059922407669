<template>
  <div style="max-width: 100rem; margin: auto;">
    <b-card>
      <b-card-header>
        <h1 class="info">
          <feather-icon
            icon="BookOpenIcon"
            size="24"
          >

          </feather-icon>
          Terms of Service</h1>
      </b-card-header>
      <b-card-body>
        <p><strong>Accurate Information Pertaining to User</strong> : Users need to fill in the correct full name when registering an account, and fill in the email and facebook ID</p>
        <p><strong>User:</strong> All forms of sharing and resell are strictly prohibited, if our team detects it, we will immediately block the account and do not refund.</p>
        <p><strong>Term:</strong> the term is calculated from the time the user buys, can turn on auto renew mode</p>
        <p><strong>Refunds:</strong> To avoid abuse, we will issue a refund when the proxy is down or after a day our proxy is not suitable for your project</p>
        <p><strong>Non-Payment:</strong> If you don't pay when the service expires, the purchased services will be canceled</p>
        <h4>Medium of Payment: We only accept bank transfer</h4>
        <p><strong>Compliance with Laws:</strong> Customer agrees to abide by all laws, foreign and domestic, local, state, and federal. It is strictly forbidden to use our products to commit any violations of the laws, illegal activities or crimes and the regulations of the relevant countries. The user is responsible for the related responsibilities. Any actions taken by users using the service do not represent our will and opinions. We are not responsible for this.
        </p>
        <h4>Privacy Policy</h4>
        <p><strong>The Information We Collect : We will continuously monitor your account to ensure and improve service, and to investigate abusive processes if you are in violation.</strong></p>
        <p><strong>Change of Ownership: Contact us to get the account transferred and as soon as the agreement is over we will update the account information.</strong></p>
        <p><strong>Changes to this Privacy Policy: We will regularly update our policy, stay tuned at our website, if you continue to use it means you accept our latest policy.</strong></p>
        <p><strong>Contact Us: If you feel that the service has a problem that needs fixing or upgrading and someone is hacking your account! </strong></p>
        <br><br>
        <p><strong>FAQ</strong></p>
        <p><strong>1: What type of proxies are these?
        <p>These are HTTP/HTTPS & Socks 4/5 proxies.</p>
        </strong></p>

        <p><strong>2: Can I email with your proxies?
        <p>Yes, we accept sending mail but do not abuse and spam leading to quality loss proxies</p>
        </strong></p>

        <p><strong>3: Can I test a proxy before purchasing?
        <p>To avoid abuse we do not provide a trial version</p>
        We will issue a refund when after a day our proxy is not suitable for your project
        </strong></p>

        <p><h4>4: Can i use your proxies though i have dynamic ip?</h4>
        <strong><p>Yes you can re-add your ip addresses
        And we have a limit on the number of ip addresses added to each port, depending on the time we will edit it to notify you.
        </p></strong> 

        <p><strong>5: Are your IP addresses recognized by proxy testers as proxies?
        <p>As far as we can tell they are not recognized. However if you find one that recognizes them as proxies please let us know.</p>
        </strong></p>

        <p><strong>6: How fast are your proxies?
        <p>Speed depends on the country and also on your network conditionHowever, all proxies are guaranteed to be fast</p>
        </strong></p>

        <p><strong>7: Can you prepare a custom proxy package for me?
        <p>Contact us for custom package deal</p>
        </strong></p>

        <p><strong>8: Can I choose my proxies locations?
        <p>You can choose any country in our system</p>
        </strong></p>

        <p><strong>9: My proxies are not working. How to fix it?
        <p>There's a lot of reasons why proxies are not working. Please do the following: </p>
        <p class="small">1. If you set IP AUTH ensure you have set your authorized IP at control panel
        <p class="small">2. Try using fewer connections.</p>
        <p class="small">3. If you're using a bot, ensure 3-5 seconds delay and rotate IPs frequently. </p>
        <p>If you still see issues, please submit a ticket to support and provide the following details: </p>
        <p class="small">What error message are you getting?</p>
        <p class="small">Where are you using the proxies? </p>
        <p class="small">What url are you trying to visit?</p>
        <p class="small">Provide the proxies you're having issues with.</p>
        </strong></p>

        <p><strong>10:  can i set IPS rotation time ?
        <p>You can set 1-60 custom minutes on each port and can choose to change ips anytime you want
          </p>
        </strong></p>
      </b-card-body>
    </b-card>
    
  </div>
</template>

<script>
import { BCard, BCardText,BTabs,BTab, BFormTextarea,BBadge,BCardHeader,BCardBody } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    BTab,BFormTextarea,
    VuePerfectScrollbar,
    AppCollapse,
    AppCollapseItem,
    BBadge,
    BCardHeader,
    BCardBody
  },
  data(){
    return{
      userInfo: this.$store.getters['auth/userInfo']
    }
  },
  setup(){
    const perfectScrollbarSettings = {
      maxScrollbarLength: 120,
      wheelPropagation: false,
      suppressScrollY: true,
      useBothWheelAxes: true
    }
    

    return {
      perfectScrollbarSettings,
    }
  }
}
</script>

<style>
  .dlborder{
    border-bottom: 1px solid #e9eff5;
  }
  .code{
    overflow-x: auto;
    border-radius: .4rem;
    background-color: #f8fbfd;
    border: 1px solid #e9eff5;
  }
  p.small {
    line-height: 0.5;
  }
</style>
